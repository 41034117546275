import React, { useState } from 'react';
import Background from '../img/iphone.png';

const EmailConfig = () => {
    const [email, setEmail] = useState('');

    const handleEmailChange = (e) => {
        setEmail(e.target.value);
    };

    const extractDomain = (email) => {
        const parts = email.split('@');
        return parts.length === 2 ? `mail.${parts[1]}` : '';
    };

    const domain = extractDomain(email);

    return (
        <div className='iphone' style={{ backgroundImage: `url(${Background})` }}>
        <div className='email-config'>
        <div className='toggle'><div><span className='active'>IMAP</span><span>POP</span></div></div>
        <section>
        <fieldset>
            <div><label>Nom</label><span className='placeholder'>Votre nom | Votre société</span></div>
            <div><label>Adresse</label> <input type="email" placeholder="Entrez votre adresse email" value={email} onChange={handleEmailChange}/></div>
            <div><label>Description</label><span className='placeholder'>Votre description</span></div>
        </fieldset>
        </section>     
        <section>
        <h6>Serveur de réception</h6>
        <fieldset>
            <div><label>Nom d'hôte</label><span>{domain}</span></div>
            <div><label>Nom d'utilisateur</label><span>{email}</span></div>
            <div><label>Mot de passe</label><span className='placeholder'>Votre mot de passe</span></div>
        </fieldset>
        </section>  
        
        <section>
        <h6>Serveur d'envoi</h6>
        <fieldset>
            <div><label>Nom d'hôte</label><span>{domain}</span></div>
            <div><label>Nom d'utilisateur</label><span>{email}</span></div>
            <div><label>Mot de passe</label><span className='placeholder'>Votre mot de passe</span></div>
        </fieldset>
        </section>
        </div>
        </div>
    );
};

export default EmailConfig;
