import './App.css';
import EmailConfig from './components/EmailConfig';

function App() {
  return (
    <div className="App">
      <EmailConfig />
    </div>
  );
}

export default App;
